// Helper classes

.hide {
  display: none;
}

.float-left {
  float: left;
}

i.spin {
  animation: spinner 0.75s infinite linear;
}

.float-right {
  float: right;
}

.align-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.clickable {
  cursor: pointer;
}

.cf {
  @include cf;
}

.stretch {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.center-cont {
  text-align: center;
  &:before {
    content: "";
    height: 100%;
    display: inline-block;
    vertical-align: middle;
  }
  .center {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
  }
}

.pull-right {
  margin-left: auto;
}

.pull-left {
  margin-right: auto;
}
