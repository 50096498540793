.select-label {
  padding: 0 0 10px 0;
}

.select {
  position: relative;
  width: 100%;
  font-size: 15px;
  &:focus {
    outline: none;
  }
}

.select_value-cont {
  width: 100%;
  cursor: text;
  &:hover .select_triangle {
    display: inline-block;
    content: '';
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 6px 5px 0 5px;
    border-color: #8f9296 transparent transparent transparent;
  }
}

.select_triangle {
  position: absolute;
  top: 12px;
  cursor: pointer;
  user-select: none;
  display: inline-block;
  content: '';
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 6px 5px 0 5px;
  border-color: #b7b8bb transparent transparent transparent;
}

.select_triangle_hidden {
  display: none;
}

.select_clear {
  position: absolute;
  top: 5px;
  padding: 5px;
  font-size: 11px;
  color: #afb0b4;
  cursor: pointer;
  user-select: none;
  &:hover {
    color: #dd584f;
  }
}

.select_placeholder {
  height: 28px;
  line-height: 28px;
  color: #6d6e70;
}

.select_value-single {
  height: 28px;
  line-height: 28px;
}

.select_value-multi {
  padding-top: 8px;
  min-height: 28px;
}

.select_value-multi-item {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  padding-left: 6px;
  height: 20px;
  line-height: 18px;
  border-radius: 2px;
  font-size: 13px;
  color: #fff;
  background: #00beef;
  i {
    height: 20px;
    padding: 0 6px 0 3px;
    font-size: 12px;
    cursor: pointer;
    user-select: none;
    &:hover {
      transform: scale(1.2);
    }
    &:before {
      position: relative;
      top: 1px;
    }
  }
}

.select_dropdown {
  z-index: 10;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding-bottom: 5px;
  background: #fff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
}

.select_dropdown-hidden {
  display: none;
}

.select_dropup {
  z-index: 10;
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  padding-bottom: 5px;
  background: #fff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
}

.select_dropup-hidden {
  display: none;
}

.select_input {
  padding: 10px 10px 5px;
  width: 100%;
  border: none;
  color: #333;
  background: none;
}

.select_input_hidden {
  display: none;
}

.select_input:focus {
  outline: none;
}

.select_options {
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: scroll;
  overflow-y: overlay;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background: #d1d4d8;
  }
  &::-webkit-scrollbar-track {
    background: none;
  }
}

.select_options-empty-label {
  height: 26px;
  line-height: 26px;
  padding: 0 10px;
  color: #b3b3b3;
}

.select_option {
  height: 26px;
  line-height: 26px;
  padding: 0 10px;
  &:hover {
    cursor: pointer;
  }
  &.is-active {
    background: #f2f3f4;
  }
  &.is-selected {
    color: #fff;
    background: #00beef;
  }
}

.select_line {
  border-bottom: 1px solid #d1d2d4;
  &:focus {
    border-bottom: 1px solid #00beef;
  }
  &.is-invalid {
    border-bottom: 1px solid #dd584f !important;
  }
  &.is-disabled {
    border-bottom: 1px solid #e6e7e9 !important;
  }
  .select_triangle {
    right: 0px;
  }
  .select_clear {
    right: 15px;
  }
}

.select_box {
  border: 1px solid #d1d2d4;
  background: #fff;
  &:focus {
    border: 1px solid #b7b8bb;
  }
  &.is-invalid {
    border: 1px solid rgba(221, 88, 79, 0.4);
    &:focus {
      border: 1px solid rgba(221, 88, 79, 0.55);
    }
  }
  .select_placeholder,
  .select_value-single,
  .select_value-multi {
    padding-left: 10px;
  }
  .select_triangle {
    right: 10px;
  }
  .select_clear {
    right: 25px;
  }
}
