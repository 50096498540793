.checkbox {
  vertical-align: middle;
  position: relative;
  display: inline-block;
  height: 22px;
  width: 22px;
  border: 1px solid #d1d2d4;
  border-radius: 5px;
  background: transparent;
  cursor: pointer;
  user-select: none;
  i {
    position: absolute;
    top: 2px;
    left: 2px;
    font-size: 16px;
    color: #333;
  }
}

.checkbox_label {
  padding-left: 12px;
  vertical-align: middle;
  display: inline-block;
  color: #9a9a9a;
  font-size: 13px;
}

.checkbox {
  &.is-invalid {
    border: 2px solid rgba(221, 88, 79, 0.4);
  }
  &.disabled {
    background-color: #e6e6e6;
    cursor: default;
  }
  &.custom_checkbox {
    margin-top: 10px;
    height: 23px;
    width: 23px;
    i {
      top: 0px;
      left: 0px;
      font-size: 18px;
    }
  }
}
