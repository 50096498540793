.listTable {
  &_top-bar {
    padding: 15px 0 0px;
    @extend .cf;
    .form_element {
      display: inline-block;
    }
    .form_element,
    .button {
      margin-bottom: 15px;
    }
    .input,
    .select,
    .dateTimePicker_inner {
      width: 250px;
      display: inline-block;
      margin-right: 15px;
      vertical-align: top;
    }
    .input {
      max-width: 350px;
    }
    &-filter {
      vertical-align: top;
      margin-right: 15px;
    }
    &-create {
      float: right;
      i {
        top: 3px;
      }
    }
  }
}
