html,
body,
#root {
  height: 100%;
  min-height: 100%;
}

body {
  position: relative;
  width: 100%;
  min-width: $min-site-width;
  background: $color-background-lightest;
}

#root {
  width: 100%;
}

*,
*:after,
*:before,
input[type='search'] {
  box-sizing: border-box;
}

button,
input,
textarea {
  outline: none;
  &:focus {
    outline: none;
  }
}

button {
  background: none;
  border: none;
}

button,
ul,
li,
ol,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}

a,
a:hover,
a:visited,
a:active {
  text-decoration: none;
  color: inherit;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: inset 0 0 0px 9999px white;
  -webkit-text-fill-color: #fff;
  background-color: #fff !important;
}

textarea:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0px 9999px white;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #6d6e70 !important;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

body,
select {
  font-smoothing: subpixel-antialiased !important;
}

::selection {
  background: $color-selection;
}

html {
  touch-action: manipulation;
}

textarea {
  resize: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

// Typography

body {
  color: $color-text;
  @include font-size(16);
  font-weight: normal;
  @include font-regular;
}

strong {
  @include font-bold;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1;
  font-weight: normal;
  @include font-bold;
}

h1 {
  @include font-size(42);
}

h2 {
  @include font-size(36);
}

h3 {
  @include font-size(30);
}

h4 {
  @include font-size(24);
}

h5 {
  @include font-size(18);
}

h6 {
  @include font-size(14);
}

p {
  line-height: 1.5;
  hyphens: auto;
  font-weight: normal;
  @include font-regular;
}

// @include placeholder-color(red);
