.validationList {
  margin-bottom: 20px;
}

.form_label {
  display: inline-block;
  color: darken($color-text-light, 10%);
  @include font-size(13);
  padding-left: 30px;
}

.custom_fields {
  margin-top: 10px;
  label {
    padding-bottom: 10px;
  }
  .select_value-single {
    margin-left: 15px;
  }
  .checkbox {
    margin-top: 5px;
  }
}

.custom_label {
  margin: 15px 0 0 5px;
}

.form {
  margin: 40px auto;
  padding: 30px;
  background: $color-background-lightest;
  position: relative;
  @extend .cf;
  &.form-auto-width {
    width: 100%;
  }
  &.form-no-margin {
    margin: 0 auto;
  }
  &.form-small {
    width: 600px;
  }
  &.form-medium {
    width: 750px;
  }
  &.form-large {
    width: 950px;
  }
  &.form-widget {
    width: 825px;
    .user-container {
      &.modified {
        flex-direction: row;
        justify-content: left;
        align-items: flex-start;

        .add-users {
          margin-bottom: 0;
        }

        .user-text {
          margin-left: 10px;
        }
      }
      display: flex;
      justify-content: center;
      align-items: center;
      height: 350px;
      flex-direction: column;
      .add-users {
        margin-bottom: 20px;
      }
      .form_heading span {
        color: #00aeef;
        @include font-bold;
      }
    }
    i {
      background: #fff !important;
    }
    .i-upload {
      background: none !important;
      top: 9px;
    }
  }

  &.address-container {
    .form_element {
      margin-bottom: 10px;
      padding-bottom: 0;
    }
  }

  &.form-danger {
    border: solid 1px $color-danger;
  }

  &-inline {
    margin: 0;
    padding: 15px;
  }

  .form_heading {
    padding: 20px 0 0;
    @include font-size(24);
    @include font-bold;
    &:first-of-type {
      padding-top: 0;
    }
  }

  .form_sub-heading {
    padding: 16px 0 40px;
    @include font-size(18);
    @include font-light;
    &:first-of-type {
      padding-top: 0;
    }
  }

  .form_element {
    vertical-align: middle;
    padding-bottom: 20px;
    &.is-invalid .form_label {
      color: $color-danger;
    }
  }

  .form_cancel {
    float: left;
    margin-top: 20px;
  }

  .form_delete {
    float: left;
    margin-top: 27px;
    margin-left: 20px;
    font-size: 26px;
    color: $color-danger;
    @include clickable-icon;
  }

  .form_submit {
    float: right;
    margin-top: 20px;
    &_padded {
      margin-right: 20px;
    }
  }

  .form_assignment,
  .form_toggle {
    margin-bottom: 15px;
    margin-right: 15px;
  }
}

.form_element-input,
.form_element-select {
  margin-right: 15px;
  vertical-align: top;
}
