.cf,
.pagination {
  *zoom: 1;
}

.cf:before,
.pagination:before,
.cf:after,
.pagination:after {
  content: ' ';
  display: table;
}

.cf:after {
  clear: both;
}

.pagination {
  &:after {
    clear: both;
  }
  padding-top: 20px;
}

.pagination_text {
  float: left;
  height: 30px;
  line-height: 30px;
  color: #b3b3b3;
  font-size: 14px;
}

.pagination_actions {
  float: right;
}

.pagination_button {
  vertical-align: middle;
  margin: 0 5px;
  color: #fff;
  background: #999;
  &:visited {
    color: #fff;
  }
  &:active,
  &.active,
  &:hover,
  &:active,
  &.active {
    background: #999;
  }
  &:hover {
    background: #ababab;
  }
  &:active,
  &.active {
    background: #878787;
  }
  i {
    font-size: 14px;
    top: 2px;
  }
  &.is-prev {
    padding-left: 10px;
  }
  &.is-next {
    padding-right: 10px;
  }
}

.pagination_item {
  vertical-align: middle;
  display: inline-block;
  margin: 0 5px;
  padding: 0 8px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #d1d2d4;
  background: #fff;
}

.pagination_item-active,
.pagination_item:hover {
  color: #fff;
  background: #999;
  border: 1px solid transparent;
  cursor: pointer;
  user-select: none;
}

.pagination_item-active {
  cursor: default;
  &:hover {
    cursor: default;
  }
}

.pagination_item:last-child {
  margin-right: 0;
}

.pagination_dots {
  vertical-align: bottom;
  display: inline-block;
  margin: 0 5px;
}
