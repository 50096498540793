.input_line {
  width: 100%;
  height: 30px;
  padding: 0;
  color: #333;
  background: none;
  border: none;
  border-bottom: 1px solid #d1d2d4;
  font-size: 15px;
  &:focus {
    outline: none;
    border-bottom: 1px solid #00beef;
  }
  &.is-invalid {
    border-bottom: 1px solid #dd584f !important;
  }
}

.input_icon-wrap-line {
  position: relative;
  i {
    position: absolute;
    top: 9px;
    font-size: 20px;
    background: #fff;
    color: #48484a;
    padding-right: 10px;
    padding-bottom: 10px;
  }
  &.input_icon-left {
    i {
      left: 0;
    }
    .input {
      padding-left: 30px;
    }
  }
  &.input_icon-right {
    i {
      right: 0;
      left: auto !important;
    }
    .input {
      padding-right: 30px;
    }
  }
  .input_error-icon {
    right: 0;
    left: auto !important;
    color: #f9a232 80;
  }
}

.input_box {
  width: 100%;
  height: 30px;
  padding: 0 10px;
  color: #333;
  border: 1px solid #d1d2d4;
  font-size: 15px;
  &:focus {
    outline: none;
    border: 1px solid #29abe2;
  }
  &.is-invalid {
    border: 1px solid rgba(221, 88, 79, 0.4);
    &:focus {
      border: 1px solid rgba(221, 88, 79, 0.55);
    }
  }
}

.input_icon-wrap-box {
  position: relative;
  i {
    position: absolute;
    top: 0;
    height: 36px;
    width: 36px;
    font-size: 20px;
    background: #3e3e3f;
    &:before {
      position: absolute;
      top: 8px;
      color: #00beef;
    }
  }
  &.input_icon-left {
    i {
      left: 0;
      &:before {
        left: 8px;
      }
    }
    .input {
      padding-left: 45px;
    }
  }
  &.input_icon-right {
    i {
      right: 0;
      &:before {
        right: 8px;
      }
    }
    .input {
      padding-right: 45px;
    }
  }
}

.input_group {
  background: 'black';
  .form_element {
    display: inline-block;
    margin-bottom: 0;
    .input {
      border-right: 0;
    }
  }
  .button {
    display: inline-block;
    margin-bottom: 20px;
  }
}
