.stretch,
.tabs_link:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.tabs_content {
  height: 100%;
}

.tabs_link {
  position: relative;
  display: inline-block;
  padding: 0 15px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  user-select: none;
}

.tabs_link-inactive {
  color: #fff;
}

.tabs_link-active {
  cursor: default;
  color: #333;
  background: #fff;
}

.tabs_link {
  &:after {
    display: none;
    content: '';
    background: rgba(255, 255, 255, 0.15);
  }
  &:not(.tabs_link-active):hover:after {
    display: inline-block;
  }
}
