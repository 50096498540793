.page {
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.page_nav {
  z-index: 14;
  position: fixed;
  left: 0;
  width: 100%;
  padding: 0 25px;
  display: flex;
}

.page_nav-item {
  padding: 0 20px;
  height: 30px;
  line-height: 30px;

  &.is-active {
    color: #333;
    background: #f2f3f4;
  }
}

.page_content {
  height: 100%;
  position: relative;
  padding: 0 8px;
  background: #f2f3f4;
  overflow-x: hidden;
  overflow-y: scroll;
  overflow-y: overlay;
  -webkit-overflow-scrolling: touch;
}

// .stretch,
// .page,
// .page_nav-item:after {
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
// }

// .page {
//   overflow: hidden;
//   .is-active {
//     cursor: default;
//     color: #333;
//     background: #f2f3f4;
//   }
// }

// .page_nav {
//   z-index: 14;
//   position: fixed;
//   left: 0;
//   width: 100%;
//   padding: 0 25px;
//   color: #333;
//   background: #dcdfe1;
// }

// .page_disabledLink {
//   position: relative;
//   display: inline-block;
//   padding: 0 20px;
//   height: 40px;
//   line-height: 40px;
//   color: currentColor;
//   cursor: default;
//   opacity: 0.5;
//   text-decoration: none;
// }

// .page_nav-item {
//   position: relative;
//   display: inline-block;
//   padding: 0 20px;
//   height: 30px;
//   line-height: 30px;
//   cursor: pointer;
//   user-select: none;
//   &.is-active {
//     cursor: default;
//     color: #333;
//     background: #f2f3f4;
//   }
//   &:after {
//     display: none;
//     content: '';
//     background: rgba(255, 255, 255, 0.15);
//   }
//   &:not(.is-active):hover:after {
//     display: inline-block;
//   }
// }

// .page_nav-right {
//   position: relative;
//   height: 40px;
//   line-height: 40px;
//   float: right;
// }

// .page_content {
//   height: 100%;
//   position: relative;
//   padding: 0 10px;
//   background: #f2f3f4;
//   overflow-x: hidden;
//   overflow-y: scroll;
//   overflow-y: overlay;
//   -webkit-overflow-scrolling: touch;
//   &::-webkit-scrollbar {
//     width: 10px;
//   }
//   &::-webkit-scrollbar-thumb {
//     background: #bfbfbf;
//   }
//   &::-webkit-scrollbar-track {
//     background: none;
//   }
// }
