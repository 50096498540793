// Mixins
@mixin cf() {
  *zoom: 1;
  &:before,
  &:after {
    content: ' ';
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin scrollableVertical($width, $thumbBg: lighten($color-background-dark, 15%), $trackBg: none) {
  overflow-x: hidden;
  overflow-y: scroll;
  overflow-y: overlay;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    width: #{$width}px;
  }
  &::-webkit-scrollbar-thumb {
    background: $thumbBg;
  }
  &::-webkit-scrollbar-track {
    background: $trackBg;
  }
}

@mixin circle($size, $color) {
  display: inline-block;
  width: #{$size}px;
  height: #{$size}px;
  background: $color;
  border-radius: 9999px;
}

@mixin triangle-base() {
  display: inline-block;
  content: '';
  width: 0px;
  height: 0px;
  border-style: solid;
}
@mixin triangle-down($x, $y, $color) {
  @include triangle-base;
  border-width: $x $y 0 $y;
  border-color: $color transparent transparent transparent;
}
@mixin triangle-up($x, $y, $color) {
  @include triangle-base;
  border-width: 0 $y $x $y;
  border-color: transparent transparent $color transparent;
}
@mixin triangle-right($x, $y, $color) {
  @include triangle-base;
  border-width: $x 0 $x $y;
  border-color: transparent transparent transparent $color;
}
@mixin triangle-left($x, $y, $color) {
  @include triangle-base;
  border-width: $x $y $x 0;
  border-color: transparent $color transparent transparent;
}

@mixin font-size($size) {
  font-size: #{$size}px;
}

@mixin clickable {
  cursor: pointer;
  user-select: none;
}

@mixin clickable-icon {
  @include clickable;
  &:hover {
    transform: scale(1.2);
  }
}

@mixin nudge($where, $amount) {
  position: relative;
  #{$where}: #{$amount}px;
}

@mixin action-color($background, $color: $color-lightest, $cf: ()) {
  $cf: map-merge(
    (
      lighten: true,
      amount: 7%,
      hover: true,
      active: true
    ),
    $cf
  );
  $lighten: (map-get($cf, lighten) == true);
  $amount: map-get($cf, amount);
  $hasHover: (map-get($cf, hover) == true);
  $hasActive: (map-get($cf, active) == true);

  color: $color;
  background: $background;
  &:visited {
    color: $color;
  }
  &:active,
  &.active {
    background: $background;
  }

  @if $lighten {
    &:hover,
    &:active,
    &.active {
      background: $background;
    }
    &:hover {
      @if $hasHover {
        background: lighten($background, $amount);
      }
    }
    &:active,
    &.active {
      @if $hasActive {
        background: darken($background, $amount);
      }
    }
  } @else {
    &:hover,
    &:active,
    &.active {
      background: $background;
    }
    &:hover {
      @if $hasHover {
        background: darken($background, $amount);
      }
    }
    &:active,
    &.active {
      @if $hasActive {
        background: lighten($background, $amount);
      }
    }
  }
}

@mixin box-shadow-large() {
  box-shadow: 0 10px 40px rgba(#000, 0.25);
}

@mixin box-shadow-small() {
  box-shadow: 0 1px 5px rgba(#000, 0.25);
}

@mixin placeholder-color($color) {
  ::-webkit-input-placeholder {
    color: $color;
    opacity: 1;
  }
  :-moz-placeholder {
    color: $color;
    opacity: 1;
  }
  ::-moz-placeholder {
    color: $color;
    opacity: 1;
  }
  :-ms-input-placeholder {
    color: $color;
    opacity: 1;
  }
}

@mixin hardware-accelerate {
  transform: translateZ(0);
}

@mixin quantity-query($number, $comparison: 'equal') {
  $nth: '';

  @if index(('greater' 'more' '>'), $comparison) {
    $nth: 'n + #{$number}';
  } @else if index(('less' 'fewer' '<'), $comparison) {
    $nth: '-n + #{$number}';
  } @else if index(('equal' 'same' '='), $comparison) {
    $nth: $number;
  } @else {
    @warn "Sorry, that's an invalid $comparison value.";
  }

  &:nth-last-child(#{$nth}):first-child {
    &,
    & ~ * {
      @content;
    }
  }
}
