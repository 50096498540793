@mixin module-colors($color) {
  .page_nav {
    color: $color-text-lightest;
    background: $color;
  }
  .accordionBox_header {
    background: $color;
  }
  .button.button_default-type, .toggle.is-toggled-true {
    @include action-color($color);
  }
  .input_line, .textarea_line {
    &:focus {
      border-bottom: 1px solid $color;
    }
  }
  .input_box, .textarea_box {
    &:focus {
      border: 1px solid $color;
    }
  }
  .select_value-multi-item {
    background: $color;
  }
  .select_line, .select_box {
    .select_option.is-selected {
      background: $color;
    }
  }
  .select_line:focus {
    border-bottom: 1px solid $color;
  }
  .modal_header {
    background: $color;
  }
  .table_header {
    color: $color;
  }
  .tabs_link-inactive {
    background: $color;
  }
  .spinner {
    border-color: rgba($color, 1);
    border-right-color: rgba($color, 0.5);
    border-left-color: rgba($color, 0.5);
	}
	
	.reportViewer {
		width:100%;
		height:100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;		
  }
  
  .filter-options {
    border: 2px solid $color;
    background-color: #fff;
  }
  .listTable_top-bar-filter.is-open-filter:after {
    background-color: $color;
  }
  .ordered-list_container_title {
    color: $color;
  }
}
