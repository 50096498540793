.cf,
.nav_dropdown-user-item {
  *zoom: 1;
}

.cf:before,
.nav_dropdown-user-item:before,
.cf:after,
.nav_dropdown-user-item:after {
  content: " ";
  display: table;
}

.cf:after,
.nav_dropdown-user-item:after {
  clear: both;
}

.nav {
  display: flex;
  background: #3c3c3d;
  color: #949497;
  position: fixed;
  width: 100%;
  z-index: 100;
}

.nav_item-icon {
  font-size: 26px;
}

.nav-item-chevron {
  margin-left: auto;
  font-size: 20px;
}

// Dropdowns
.nav_dropdown-options,
.nav_dropdown-user {
  background: #3c3c3d;
  color: #c8c8c9;
  position: absolute;
  top: 100%;
  left: 0px;
  width: 100%;
  padding: 0 10px 10px;

  .nav_dropdown-item {
    display: block;
    padding: 10px 0px 10px 10px;
    cursor: pointer;
    user-select: none;

    & + .nav_dropdown-item {
      border-top: 1px solid #5c5c5e;
    }

    &:hover i {
      color: #fff;
    }

    .nav_dropdown-title {
      display: flex;
      font-size: 15px;

      i {
        margin-left: auto;
      }
    }
  }
}

// Logo
.nav_item-logo {
  background: #48484a;
  position: relative;
  margin-right: 20px;
  height: 80px;
  padding: 10px;

  &:after {
    content: "";
    position: absolute;
    left: 100%;
    top: 0;
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 40px 0 40px 20px;
    border-color: transparent transparent transparent #48484a;
  }

  .nav_logo-inner {
    display: flex;
    align-items: center;

    .nav_logo-img {
      max-height: 60px;
    }
  }
}

// Options
.nav_item-options {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 20px 0 45px;
  margin-left: -20px; // indent behind logo arrow
  width: 260px;

  &:hover {
    .nav-item-chevron {
      color: #bbbbbc;
    }
  }

  .nav_item-name {
    margin-left: 20px;

    .nav_item-title {
      text-transform: uppercase;
      color: #c8c8c9;
      font-size: 15px;
      font-family: robotobold, arial, "sans-serif";
    }

    .nav_item-subtitle {
      font-size: 13px;
      text-transform: capitalize;
    }
  }
}

// User
.nav_item-user {
  margin-left: auto;
  display: flex;
  align-items: center;
  position: relative;

  .nav_avatar-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .nav_username-details {
    display: inline-block;

    .nav_username-details-user {
      color: #00beef;
      font-size: 16px;
      padding-bottom: 5px;
    }

    .nav_username-details-org {
      color: #aeaeb0;
      font-size: 14px;
    }
  }

  > i {
    font-size: 20px;
    margin: 0 20px;
  }

  // Dropdown
  .nav_dropdown-user-links {
    margin-bottom: 15px;
    a {
      display: flex;

      i {
        margin-left: auto;
        font-size: 20px;
        color: #00beef;
      }
    }
  }

  .nav_sign-out {
    background: #00beef;
    margin-bottom: 10px;
  }
}

// .nav_item {
//   position: relative;
//   height: 80px;
//   float: left;
//   cursor: pointer;
//   user-select: none;
//   &:hover .i-chevron-down {
//     color: #bbbbbc;
//   }
// }

// .nav .i-chevron-down {
//   position: absolute;
//   top: 29px;
//   right: 20px;
//   font-size: 20px;
// }

// .nav_dropdown {
//   position: absolute;
//   top: 100%;
//   left: 0;
//   width: 100%;
//   padding: 0 10px 7px;
//   cursor: default;
//   max-height: 80vh;
// }

// .nav_scroll {
//   display: flex;
//   .custom-scrollbar {
//     right: 0;
//     width: 6px;
//   }
//   .inner-handle {
//     border-radius: 0;
//     background: #535a62;
//   }
// }

// .nav_dropdown-item {
//   display: block;
//   position: relative;
//   padding: 12px 0px 12px 10px;
//   cursor: pointer;
//   user-select: none;
//   &:first-of-type {
//     border-top: none;
//   }
//   &:hover i {
//     color: #fff;
//   }
// }

// .nav-brand {
//   color: red;

//   &:after {
//     position: absolute;
//     left: 100%;
//     top: 0;
//     display: inline-block;
//     content: "";
//     width: 0;
//     height: 0;
//     border-style: solid;
//     border-width: 40px 0 40px 20px;
//     border-color: transparent transparent transparent #48484a;
//   }
// }

// .nav_logo-inner {
//   display: inline-block;
//   position: relative;
// }

// .nav_logo-img {
//   display: block;
//   // padding-top: 10px;
//   // width: 100%;
//   width: 250px;
//   height: auto;
// }

// .nav_logo-module {
//   position: absolute;
//   bottom: -8px;
//   right: 11px;
//   color: #00beef;
//   font-weight: 200;
//   font-size: 13px;
// }

// .nav_dropdown-modules {
//   background: #48484a;
// }

// .nav_dropdown-modules-item {
//   padding-left: 50px;
//   color: #a6a6a8;
//   border-top: 1px solid #69696b;
//   i {
//     position: absolute;
//     top: 13px;
//     left: 0px;
//     font-size: 38px;
//   }
//   .nav_dropdown-title {
//     padding-bottom: 3px;
//     color: #fff;
//     font-weight: 600;
//     font-size: 18px;
//   }
//   .nav_dropdown-subtitle {
//     font-size: 13px;
//   }
// }

// .nav_item-options {
//   min-width: 260px;
//   padding: 20px 10px 0 10px;
//   background: #3c3c3d;
//   .nav_item-options-icon {
//     position: absolute;
//     left: 43px;
//     top: 27px;
//     font-size: 26px;
//   }
//   .nav_item-title {
//     text-transform: uppercase;
//     color: #c8c8c9;
//     font-size: 15px;
//     font-weight: 900;
//   }
//   .nav_item-subtitle {
//     font-size: 13px;
//   }
// }

// .nav_dropdown-options {
//   background: #3c3c3d;
// }

// .nav_dropdown-options-item {
//   padding-bottom: 10px;
//   border-top: 1px solid #5c5c5e;
//   i {
//     color: #7b7b7d;
//     font-size: 20px;
//   }
//   .sub-menu {
//     float: right;
//     padding: 0 10px;
//   }
//   .subMenu_tray {
//     padding: 5px;
//   }
//   .nav_dropdown-title {
//     display: inline-block;
//     padding-left: 10px;
//     color: #c8c8c9;
//     font-size: 15px;
//   }
// }

// .nav_item-user {
//   min-width: 160px;
//   padding: 20px 20px 0 20px;
// }

// .nav_item-user_container {
//   float: right;
//   padding-right: 20px;
// }

// .nav_username-details-user {
//   color: #f2f3f4;
//   font-size: 16px;
//   padding-bottom: 5px;
// }

// .nav_username-details-org {
//   color: #aeaeb0;
//   font-size: 14px;
//   text-align: right;
// }

// .nav_dropdown-user {
//   min-width: 290px;
//   left: auto;
//   right: 0;
//   padding: 0;
//   background: #48484a;
// }

// .nav_dropdown-user-top {
//   padding: 10px;
//   background: #525254;
// }

// .nav_dropdown-user-username,
// .nav_dropdown-user-current-org {
//   color: #aeaeb0;
//   font-size: 14px;
//   text-align: right;
// }

// .nav_dropdown-user-username span,
// .nav_dropdown-user-current-org span {
//   width: 100%;
// }

// .nav_dropdown-user-links {
//   padding: 0 10px;
// }

// .nav_dropdown-user {
//   .align-center {
//     padding: 10px 0 20px;
//   }
//   .button {
//     text-transform: uppercase;
//   }
// }

// .nav_dropdown-user-item {
//   padding-bottom: 10px;
//   border-top: 1px solid #69696b;
//   .nav_dropdown-title {
//     float: left;
//     color: #c8c8c9;
//     font-size: 17px;
//   }
//   i {
//     float: right;
//     font-size: 22px;
//     color: #00beef;
//   }
//   &:hover {
//     .nav_dropdown-title {
//       color: #fff;
//     }
//     i {
//       color: #00beef;
//     }
//   }
// }
