.validationList {
  text-align: center;
  color: #fff;
  background: rgba(221, 88, 79, 0.7);
  padding: 10px;
  margin-bottom: 20px;
  &-round {
    &:after {
      position: relative;
      display: inline-block;
      content: '';
      height: 60px;
      width: 60px;
      border-radius: 99px;
      background: #fff;
    }
  }
}

.validationList_heading {
  display: none;
}

.validationList_items li {
  padding-bottom: 2px;
  &:last-of-type {
    padding-bottom: 0;
  }
}
