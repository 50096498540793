.fileUpload {
  display: inline-block;
  cursor: pointer;
  background: #ababab;
  i.i-upload {
    background: transparent !important;
  }
}

input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.label {
  background: #fff;
  color: #111;
  padding: 15px 15px 9px 10px;
  // border-bottom: 1px solid #e4e3e3;
  font-size: 14px;
}
