.itemPicker {
  display: inline-block;
  position: relative;
}

.itemPicker_dropdown-cont {
  z-index: 10;
  position: absolute;
  padding: 15px 0;
}

.itemPicker_dropdown {
  width: 100%;
  padding: 6px 0;
  background: #d2d2d2;
  overflow-x: hidden;
  overflow-y: scroll;
  overflow-y: overlay;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background: #f2f3f4;
  }
  &::-webkit-scrollbar-track {
    background: none;
  }
}

.itemPicker_item {
  padding: 8px 15px;
  cursor: pointer;
  user-select: none;
  font-size: 15px;
  &:hover {
    // background: #f2f3f4;
  }
}

.itemPicker_empty-label {
  text-align: center;
  // color: #d9d9d9;
  // font-size: 20px;
  font-family: 'robotomedium', 'arial', 'sans-serif';
}
