.cf,
.assignment,
.assignment_bottom {
  *zoom: 1;
}

.cf:before,
.assignment:before,
.assignment_bottom:before,
.cf:after,
.assignment:after,
.assignment_bottom:after {
  content: ' ';
  display: table;
}

.cf:after,
.assignment:after,
.assignment_bottom:after {
  clear: both;
}

.assignment {
  display: flex;
  flex-direction: column;
  padding: 25px 30px 30px;
  height: 80vh;
  max-height: 500px;
  width: 980px;
  background: #fff;
}

.assignment_high {
  max-height: 700px;
}

.assignment_content {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
}

.assignment_list {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 42%;
  max-height: 400px;
}

.assignment_list-available-move-all,
.assignment_list-selected-move-all {
  position: absolute;
  top: 101%;
  color: #777;
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
  font-size: 13px;
}

.assignment_list-available-move-all:hover,
.assignment_list-selected-move-all:hover {
  color: #333;
}

.assignment_list-search {
  flex: 0 0 auto;
  padding-bottom: 8px;
  font-size: 20px;
  font-family: 'robotomedium', 'arial', 'sans-serif';
}

.assignment_list-title {
  flex: 0 0 auto;
  padding-bottom: 12px;
  font-size: 20px;
  font-family: 'robotomedium', 'arial', 'sans-serif';
}

.assignment_list-items {
  flex: 1 1 auto;
  position: relative;
  border: 2px solid #e6e7e8;
  overflow-x: hidden;
  overflow-y: scroll;
  overflow-y: overlay;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #e6e7e8;
  }
  &::-webkit-scrollbar-track {
    background: none;
  }
}

.assignment_list-item {
  position: relative;
  padding-left: 60px;
  height: 40px;
  line-height: 38px;
  white-space: nowrap;
  border-bottom: 2px solid #e6e7e8;
  cursor: pointer;
  user-select: none;
  &:nth-of-type(odd) {
    background: #f5f6f6;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 45px;
    height: 40px;
    width: 2px;
    background: #e6e7e8;
  }
  &:hover .assignment_list-item-action {
    transform: scale(1.1);
  }
}

.assignment_list-item-action {
  position: absolute;
  left: 11px;
  top: 0px;
  font-size: 24px;
}

.assignment_list-available {
  .assignment_list-item-action i {
    color: #00a54f;
  }
  .assignment_list-available-move-all {
    right: 0;
  }
}

.assignment_list-selected {
  .assignment_list-item-action i {
    color: #dd584f;
  }
  .assignment_list-selected-move-all {
    left: 0;
  }
}

.assignment_switch {
  position: relative;
  width: 16%;
}

.assignment_switch_narrow {
  width: 10%;
}

.assignment .i-switch {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -40px;
  font-size: 80px;
  color: #b3b3b3;
}

.assignment_bottom {
  flex: 0 0 auto;
  padding-top: 20px;
}

.assignment_submit {
  float: right;
}
