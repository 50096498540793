.map {
  position: relative;

  &_context-menu {
    background-color: $color-background-medium;
    box-shadow: 2px 3px 6px 0px grey;
    transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;

    &::before {
      $pointer-size: 7px;
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid black;
      position: absolute;
      top: -5px;
    }

    &_button {
      padding: 6px 4px;
    }

    &_item {
      border-bottom: 1px solid $color-background-light;
      cursor: pointer;
      min-width: 120px;
      // text-align: center;
      transition: all 150ms ease-in-out;
      &:hover {
        background-color: $color-background-light;
        transition: all 150ms ease-in-out;
      }
    }
  }

  &_controls {
    z-index: 10;
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 10px;
    height: 28px;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 1px rgba(#000, 0.25);
    &_container {
      width: 100%;
      background-color: $color-background-medium;
      height: 40px;
      .view-tag {
        background-color: $color-module-tracking;
        color: #fff;
        vertical-align: middle;
        height: 100%;
        padding: 0 18px;
        font-size: 16px;
        float: right;

        &-copy {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
          display: block;
        }
      }
    }

    &-item {
      height: 100%;
      padding: 0 18px;
      border-right: 1px solid rgba($color-border, 0.5);
      @include clickable;
      font-size: 18px;
      &:hover,
      &.is-active {
        // background: rgb(235, 235, 235);
        color: $color-module-tracking;
      }
      &:active {
        // background: rgb(220, 220, 220);
        color: $color-module-tracking;
      }
      &:before {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        display: block;
      }
      &.error_no-tracking {
        border-right: 0;
        color: #da791a;
        float: right;
      }
    }
  }
  &_asset {
    $size: 18px;
    position: absolute;
    height: $size;
    width: $size;
    .map_asset-circle {
      z-index: 2;
      border-radius: 999px;
      @extend .stretch;
    }
    &-status-ignition_off {
      .map_asset-circle {
        background: $color-red;
        border: 1px solid rgba(#000, 0.4);
      }
      &.map_asset-doors-open .map_asset-circle {
        background: $color-blue;
      }
    }
    &-status-ignition_on_and_stationary {
      .map_asset-circle {
        background: $color-yellow;
        border: 1px solid rgba(#000, 0.4);
      }
      &.map_asset-doors-open .map_asset-circle {
        background: $color-blue;
      }
    }
    &-status-ignition_on_and_moving {
      i {
        z-index: 2;
        position: relative;
        bottom: 3px;
        right: 1px;
        font-size: 20px;
        color: $color-green;
        text-shadow: 0 1px 1px #000;
      }
      &.map_asset-doors-open {
        i {
          color: $color-blue;
        }
      }
    }
    .map_item {
      z-index: 3;
      cursor: pointer;
      border-radius: 999px;
      @extend .stretch;
    }
    &-label {
      display: none;
      z-index: 1;
      position: absolute;
      top: $size / 2;
      left: $size / 2;
      padding: 3px 6px 2px;
      border-radius: 3px;
      color: #eee;
      background: rgba(#000, 0.8);
      user-select: none;
    }
  }
  &_show-asset-labels .map_asset-label {
    display: inline-block;
  }
  &_label {
    position: absolute;
    padding: 3px 6px 2px;
    border-radius: 3px;
    color: #eee;
    background: rgba(#000, 0.8);
    cursor: pointer;
    user-select: none;
    i {
      position: relative;
      top: 1px;
      right: 1px;
    }
  }
  &_tripPoint {
    position: absolute;
    height: 18px;
    width: 18px;
    i {
      z-index: 2;
      position: relative;
      bottom: 3px;
      right: 1px;
      font-size: 20px;
      color: $color-green;
      text-shadow: 0 1px 1px #000;
    }
    &-yellow i {
      color: $color-yellow;
    }
    &-red i {
      color: $color-red;
    }
    .map_item {
      z-index: 3;
      cursor: pointer;
      border-radius: 999px;
      @extend .stretch;
    }
  }
  &_notification {
    z-index: 8;
    position: absolute;
    width: 18px;
    height: 18px;
    border: 2px solid #fff;
    border-radius: 999px;
    background: $color-red;
  }

  &_tooltip {
    //display: none;
    z-index: 9999; //position: fixed;
    padding: 4px; // width: 300px;
    //cursor: default;
    color: #eee;
    background: rgba(#000, 0.8);

    &-warning {
      color: orange;
    }

    &-title {
      @include font-size(14);
      font-weight: bold;
      margin-bottom: 6px;
    }

    &-item {
      // padding: 1px 0;
      width: 100%;
      padding: 2px;
      padding-left: 0;
      @include font-size(12);
      @extend .cf;
    }
    &-key {
      float: left;
      width: 40%;
    }
    &-value {
      float: right;
      width: 60%;
    }
    &:after {
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      @include triangle-base;
      @include triangle-down(5px, 5px, rgba(#000, 0.8));
    }
  }
  &_tooltip-default {
    z-index: 9999;
    padding: 4px;
    color: #eee;
    background: rgba(#000, 0.8);
    &-title {
      // padding: 1px 0;
      text-transform: uppercase;
      @include font-size(13);
    }
    &-item {
      // padding: 1px 0;
      width: 100%;
      @include font-size(12);
      @extend .cf;
    }
    &-key {
      float: left;
      width: 40%;
    }
    &-value {
      float: right;
      width: 60%;
    }
  }
  &_tooltip-top {
    z-index: 9999;
    padding: 7px 10px;
    max-width: 300px;
    color: #eee;
    background: rgba(#000, 0.8);
    &-title {
      padding: 5px 0;
      text-transform: uppercase;
      @include font-size(13);
    }
    &-item {
      padding: 1px 0;
      width: 100%;
      @include font-size(12);
      @extend .cf;
    }
    &-key {
      float: left;
      width: 40%;
    }
    &-value {
      float: right;
      width: 60%;
    }
    &:after {
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      @include triangle-base;
      @include triangle-down(5px, 5px, rgba(#000, 0.8));
    }
  }
  &_tooltip-bottom {
    z-index: 9999;
    padding: 7px 10px;
    width: 300px;
    color: #eee;
    background: rgba(#000, 0.8);
    &-title {
      padding: 5px 0;
      text-transform: uppercase;
      @include font-size(13);
    }
    &-item {
      padding: 1px 0;
      width: 100%;
      @include font-size(12);
      @extend .cf;
    }
    &-key {
      float: left;
      width: 40%;
    }
    &-value {
      float: right;
      width: 60%;
    }
    &:after {
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      @include triangle-base;
      @include triangle-up(5px, 5px, rgba(#000, 0.8));
    }
  }
  &_menu {
    z-index: 9999;
    position: fixed;
    width: 200px;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 1px rgba(#000, 0.25);
    @include font-size(13);
    &-item {
      height: 24px;
      line-height: 24px;
      padding: 0 8px;
      border-bottom: 1px solid rgba($color-border, 0.5);
      @include clickable;
      &:hover,
      &.is-active {
        background: rgb(235, 235, 235);
      }
      &:active {
        background: rgb(220, 220, 220);
      }
    }
  }
  &_crudResetButton {
    margin-top: 10px;
    margin-right: 10px;
    padding: 0 10px;
    height: 28px;
    line-height: 28px;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 1px rgba(#000, 0.25);
    @include clickable;
    &:hover {
      background: rgb(235, 235, 235);
    }
    i {
      position: relative;
      top: 1px;
      margin-right: 4px;
      font-size: 12px;
    }
  }
  &_addressSearchInput {
    background-color: rgba(#fff, 1);
    font-family: Roboto;
    font-size: 15px;
    font-weight: 400;
    margin-left: 6px;
    margin-top: 50px; /*below controls bar*/
    padding: 5px 11px 5px 13px;
    text-overflow: ellipsis;
    width: 70%;
  }
  &_directions,
  &_directions-tip {
    z-index: 9999;
    position: fixed;
    top: 50px;
    right: 0;
    background: #fff;
    box-shadow: 0 1px 1px rgba(#000, 0.25);
  }
  &_directions {
    z-index: 99;
    right: 10px;
    font-size: 11px;
    padding: 0 5px;
    width: 270px;
    max-height: 200px;
    @include scrollableVertical(5);
  }
  &_directions-tip {
    z-index: 1;
    padding: 5px;
    width: 200px;
    @include font-size(15);
  }
  &_panel {
    &_close {
      position: fixed;
      right: 20px;
      top: 10px;
      > .i-close {
        color: $color-module-tracking;
        font-size: 20px;
        @include clickable;
        &:hover {
          transform: scale(1.2);
        }
      }
    }
    &_waypoints-wrapper,
    &_directions-wrapper {
      margin: 5px;
      font-size: 14px;
      .title {
        color: $color-module-tracking;
        font-size: 16px;
      }
    }
    &_waypoints-wrapper {
      border-bottom: 1px solid $color-background-dark;
      padding-bottom: 13px;

      .title {
        padding-bottom: 3px;
      }

      .waypoint-num-container {
        .waypoint-num {
          font-weight: 600;
          color: green;
        }
        &:last-child {
          .waypoint-num {
            font-weight: 600;
            color: red;
          }
        }
        .lat,
        .lng {
          padding-left: 10px;
        }
        .i-close {
          color: $color-text-light;
          font-size: 12px;
          @include clickable;
          padding-left: 6px;
          line-height: 15px;
          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }
    &_directions-wrapper {
      padding-top: 8px;
    }

    // Sidebar panels on map
    &_outer-container {
      overflow-y: auto;
      height: 100%;
      position: relative;
    }
    &_inner-container {
      position: absolute;
      width: 100%;
      overflow-y: hidden;
    }
  }

  &-container {
    // Fixes issues on Google maps street view description
    .gm-iv-address {
      height: 56px;
      &-description {
        min-width: 137px;
      }
    }
  }
}
