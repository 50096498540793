.button {
  position: relative;
  display: inline-block;
  padding: 0 15px;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  white-space: nowrap;
  letter-spacing: 0.5px;
  cursor: pointer;
  user-select: none;
  color: #fff;
  background: #3c3c3d;
  text-align: center;
  &:visited {
    color: #fff;
  }
  &:active,
  &.active,
  &:hover,
  &:active,
  &.active {
    background: #3c3c3d;
  }
  &:hover {
    background: #4e4e4f;
  }
  &:active,
  &.active {
    background: #2a2a2b;
  }
  .button_content {
    font-size: 14px;
    font-family: 'robotomedium', 'arial', 'sans-serif';
  }
}

.button_primary {
  color: #fff;
  background: #00beef;
  &:visited {
    color: #fff;
  }
  &:active,
  &.active,
  &:hover,
  &:active,
  &.active {
    background: #00beef;
  }
  &:hover {
    background: #14cfff;
  }
  &:active,
  &.active {
    background: #00a2cb;
  }
}

.button_success {
  color: #fff;
  background: #00a54f;
  &:visited {
    color: #fff;
  }
  &:active,
  &.active,
  &:hover,
  &:active,
  &.active {
    background: #00a54f;
  }
  &:hover {
    background: #00c960;
  }
  &:active,
  &.active {
    background: #00813e;
  }
}

.button_warning {
  color: #fff;
  background: #f79226;
  &:visited {
    color: #fff;
  }
  &:active,
  &.active,
  &:hover,
  &:active,
  &.active {
    background: #f79226;
  }
  &:hover {
    background: #f8a348;
  }
  &:active,
  &.active {
    background: #f08109;
  }
}

.button_danger {
  color: #fff;
  background: #dd584f;
  &:visited {
    color: #fff;
  }
  &:active,
  &.active,
  &:hover,
  &:active,
  &.active {
    background: #dd584f;
  }
  &:hover {
    background: #e3746d;
  }
  &:active,
  &.active {
    background: #d73c31;
  }
}

.button_secondary {
  color: #fff;
  background: #939496;
  &:visited {
    color: #fff;
  }
  &:active,
  &.active,
  &:hover,
  &:active,
  &.active {
    background: #939496;
  }
  &:hover {
    background: #a5a6a8;
  }
  &:active,
  &.active {
    background: #818285;
  }
}

.button_light {
  color: #333;
  background: #e1e1e2;
  &:visited {
    color: #333;
  }
  &:active,
  &.active,
  &:hover,
  &:active,
  &.active {
    background: #e1e1e2;
  }
  &:hover {
    background: #cfcfcf;
  }
}

.button_lightest {
  color: #777;
  background: #fff;
  &:visited {
    color: #777;
  }
  &:active,
  &.active,
  &:hover,
  &:active,
  &.active {
    background: #fff;
  }
  &:hover {
    background: #ededed;
  }
  &:active,
  &.active {
    background: #fff;
  }
}

.button_disabled {
  cursor: default;
  color: #a6a6a6;
  background: #e6e7e9;
  &:visited {
    color: #a6a6a6;
  }
  &:active,
  &.active,
  &:hover,
  &:active,
  &.active {
    background: #e6e7e9;
  }
}

.button_only-text {
  background-color: transparent;
  color: #777;
}

.button.button_only-text:hover {
  background-color: transparent;
  color: #333;
  i {
    color: #333;
  }
}

.button_small {
  height: 30px;
  line-height: 28px;
  .button_content {
    font-size: 13px;
  }
}

.button_large {
  height: 42px;
  line-height: 40px;
  .button_content {
    font-size: 18px;
  }
}

.button i {
  position: relative;
  top: 1px;
  font-size: 18px;
}

.button_icon-left i {
  margin-right: 7px;
}

.button_icon-right i {
  margin-left: 7px;
}

.button_small i {
  font-size: 16px;
}

.button_large i {
  font-size: 22px;
  &.i-truck-right {
    font-size: 26px;
  }
}

.button_icon-block {
  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 36px;
    height: 36px;
    // background: rgba(0, 0, 0, 0.15);
  }
  i {
    position: absolute;
    top: 9px;
  }
  &.button_small {
    &:before {
      width: 30px;
      height: 30px;
    }
    i {
      top: 3px;
    }
  }
  &.button_large {
    &:before {
      width: 42px;
      height: 42px;
    }
    i {
      top: 4px;
    }
  }
  &.button_icon-left {
    &:before {
      left: 0;
    }
    i {
      left: 9px;
    }
    .button_content {
      padding-left: 34px;
    }
    &.button_small i {
      left: 7px;
    }
    &.button_large i {
      left: 10px;
    }
    &.button_small .button_content {
      padding-left: 28px;
    }
    &.button_large .button_content {
      padding-left: 40px;
    }
  }
  &.button_icon-right {
    &:before {
      right: 0;
    }
    i {
      right: 9px;
    }
    .button_content {
      padding-right: 34px;
    }
    &.button_small i {
      right: 7px;
    }
    &.button_large i {
      right: 10px;
    }
    &.button_small .button_content {
      padding-right: 28px;
    }
    &.button_large .button_content {
      padding-right: 40px;
    }
  }
  &.button_only-text {
    &:before {
      background-color: transparent;
    }
    i {
      background-color: transparent;
      color: #777;
      &:hover {
        color: #333;
      }
    }
  }
}

.button_tag {
  border-radius: 999px;
  &.button_icon-block:before {
    border-radius: 999px !important;
  }
}

.button_has-progress {
  .button_content {
    z-index: 2;
    position: relative;
  }
  i {
    z-index: 2;
  }
}

.button_progress {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0%;
  background: rgba(0, 0, 0, 0);
  &.is-active {
    animation: buttonprogress 8s infinite ease-out;
    background: rgba(0, 0, 0, 0.15);
  }
}

@-webkit-keyframes buttonprogress {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes buttonprogress {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}
