.warningDialog {
  padding: 30px;
  width: 600px;
  text-align: center !important;
  background: #fff;
}

.warningDialog [class^='Button_button'] {
  margin: 0 10px;
}

.warningDialog_icon {
  position: absolute;
  top: -39px;
  left: 50%;
  margin-left: -40px;
  font-size: 40px;
  color: #fff;
  &:after {
    position: absolute;
    top: 5px;
    left: 5px;
    display: inline-block;
    content: '';
    height: 70px;
    width: 70px;
    border-radius: 99px;
    background: #dd584f;
  }
  &:before {
    position: absolute;
    top: 18px;
    left: 20px;
    z-index: 2;
  }
}

.warningDialog_title {
  padding: 20px 0 10px;
  color: #dd584f;
  font-family: 'robotomedium', 'arial', 'sans-serif';
  font-size: 28px;
}

.warningDialog_heading {
  padding: 20px 0 10px;
  font-size: 26px;
}

.warningDialog_message {
  line-height: 1.35;
  padding-bottom: 25px;
  font-size: 20px;
}

.warningDialog .button {
  margin: 0 10px;
}
