.page_nav-item {
  position: relative;
  display: inline-block;
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  user-select: none;
  &.is-active {
    cursor: default;
    color: #333;
    background: #f2f3f4;
  }
  &:after {
    display: none;
    content: '';
    background: rgba(255, 255, 255, 0.15);
  }
  &:not(.is-active):hover:after {
    display: inline-block;
  }
}

.page_nav-right {
  position: relative;
  height: 40px;
  line-height: 40px;
  float: right;
}
