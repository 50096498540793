.avtm {
  width: 1250px;
  @include module-colors($color-avt-modal);
  @media screen and (max-width: 1270px) {
    width: 1020px;
  }
  &_main-input i {
    color: $color-avt-modal;
  }
  .modal_header {
    background: $color-avt-modal;
    i {
      position: relative;
      top: 1px;
      right: 3px;
    }
  }
  &_inner {
    height: 690px;
    background: $color-background-lightest;
    @extend .cf;
  }
  &_tabs-cont {
    float: left;
    width: 70%;
    padding: 15px;
  }
  .tabs {
    &_link {
      &-inactive {
        color: $color-text-lightest;
        background: $color-avt-modal;
      }
      &-active {
        color: $color-text;
        background: $color-background-light;
      }
    }
  }
  .map {
    float: left;
    width: 30%;
  }

  &_slst {
    &-top {
      padding: 20px 15px 25px;
      background: $color-background-light;
    }
    &-region-tip {
      padding-bottom: 15px;
      @include font-size(13);
    }
    &-search-cont {
      position: relative;
    }
    &-search-input {
      padding-left: 62px;
    }
    &-dropdown-trigger {
      position: absolute;
      // top: 1px;
      // left: 1px;
    }
    &-dropdown-link {
      width: 49px;
      height: 34px;
      line-height: 36px;
      background: $color-border;
      text-align: center;
      @include clickable;
      i {
        font-size: 14px;
        color: $color-text-lightest;
        margin: 0 2px;
      }
      i.i-chevron-down {
        position: relative;
        bottom: 1px;
      }
    }
    &-dropdown-cont {
      display: none;
      padding-top: 12px;
    }
    &-dropdown-cont_toggled {
      display: block;
      padding-top: 12px;
    }
    &-dropdown {
      position: absolute;
      top: 90%;
      z-index: 1;
      left: 0;
      padding: 15px 12px 20px;
      width: 550px;
      background: $color-background-lightest;
      @include box-shadow-small;
      &-sites {
        max-height: 150px;
        @include scrollableVertical(7);
      }
      &-site {
        padding: 3px 15px 3px 5px;
        @extend .cf;
        @include font-size(14);
        @include clickable;
        &:nth-of-type(even) {
          background: $color-background-light;
        }
        &:hover,
        &.is-selected {
          color: $color-text-lightest;
          background: $color-avt-modal;
        }
        span:first-of-type {
          float: left;
        }
        span:last-of-type {
          float: left;
        }
      }
      &-radius {
        padding-left: 5px;
        margin-top: 20px;
        .input {
          vertical-align: middle;
          margin-left: 10px;
          width: 100px;
        }
      }
    }
  }
  &_gpsct {
    &-top {
      padding: 15px 15px 20px;
      background: $color-background-light;
      @include cf;
    }
    &-top-decimal {
      margin-right: -10px;
      margin-left: -10px;
      @include cf;
      .form_element {
        min-height: 1px;
        padding-right: 10px;
        padding-left: 10px;
        float: left;
        width: 50%;
      }
    }
    &-top-normal {
      margin-right: -10px;
      margin-left: -10px;
      @include cf;
      .form_element {
        min-height: 1px;
        padding-right: 10px;
        padding-left: 10px;
        float: left;
        width: 12.5%;
      }
      i.select_clear {
        display: none !important;
      }
    }
    &-top-or {
      position: relative;
      top: 21px;
      text-align: center;
      color: darken($color-text-light, 10%);
      @include font-size(14);
    }
    .form_submit {
      margin-top: 20px;
      float: right;
    }
  }
  &_sst {
    &-top {
      padding: 15px 15px 17px;
      background: $color-background-light;
      @include cf;
      .row {
        margin-bottom: 20px;
      }
      .form_submit {
        float: right;
      }
    }
  }
  .avtm_addressForm {
    margin: 0;
    padding: 20px 15px 0;
    &-heading {
      padding-bottom: 20px;
      color: $color-avt-modal;
      @include font-size(18);
    }
    .button {
      margin-top: 7px;
    }
  }
}

.pac-container {
  z-index: 9999; // google maps api autocomplete dropdown
}
