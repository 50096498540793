.textarea_line {
  display: block;
  width: 100%;
  height: 80px;
  padding: 10px 0 10px;
  border: none;
  border-bottom: 1px solid #d1d2d4;
  background: #fff;
  font-size: 15px;
  &:focus {
    outline: none;
    border-bottom: 1px solid #00beef;
  }
  &.is-invalid {
    border-bottom: 1px solid #dd584f !important;
  }

  &.lines {
    background-image: linear-gradient(transparent, transparent 24px, #d1d2d4 0px);
    background-size: 100% 25px;
    line-height: 25px;
  }
}

.textarea_box {
  display: block;
  width: 100%;
  height: 110px;
  padding: 9px 10px 10px;
  border: 1px solid #d1d2d4;
  background: #fff;
  font-size: 15px;
  &:focus {
    outline: none;
    border: 1px solid #b7b8bb;
  }
  &.is-invalid {
    border: 1px solid rgba(221, 88, 79, 0.4);
    &:focus {
      border: 1px solid rgba(221, 88, 79, 0.55);
    }
  }
}
