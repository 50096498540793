.cf,
.table_head,
.table_row {
  *zoom: 1;
}

.cf:before,
.table_head:before,
.table_row:before,
.cf:after,
.table_head:after,
.table_row:after {
  content: ' ';
  display: table;
}

.cf:after,
.table_head:after,
.table_row:after {
  clear: both;
}

.stretch,
.table_is-loading:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.table_header {
  .i-sort,
  .i-sort-up,
  .i-sort-down {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.table {
  position: relative;
  display: block;
  width: 100%;
  border: 1px solid #d1d2d4;
  border-top: none;
  border-collapse: collapse;
  background: #fff;
 // margin: 20px 0;
}

.table_hidden {
  display: none;
}

.table_head {
  display: table;
  overflow: auto;
  width: 100%;
}

.table_header {
  position: relative;
  float: left;
  padding-left: 10px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #d1d2d4;
  border-left: none;
  font-size: 11px;
  font-family: 'robotomedium', 'arial', 'sans-serif';
  &:last-of-type {
    border-right: none;
  }
}

.table {
  .scrollable {
    height: 500px;
    overflow: auto;
  }
  .custom-scroll {
    .custom-scrollbar {
      right: 0;
      width: 6px;
    }
    .inner-handle {
      border-radius: 0;
      background: #d1d4d8;
    }
  }
}

.table_title {
  border-top: 1px solid #d1d2d4;
  text-align: center;
  height: 40px;
  line-height: 40px;
  font-weight: bold;
  font-size: 18px;
}

.table_row {
  border-bottom: 1px solid #d1d2d4;
  display: table;
  width: 100%;
  table-layout: fixed;
  &:nth-of-type(odd) {
    background: #f2f3f4;
  }
}

.table_cell {
  float: left;  
  height: 30px;
  line-height: 30px;
  border-right: 1px solid #d1d2d4;
  font-size: 11px;
  &:last-of-type {
    border-right: none;
  }
  .multi-line {
    line-height: normal;
  }
}

@media (max-width: 1281px) {
  .table_cell {
    font-size: 12px;
  }
}

.table_header-text,
.table_cell-text {
  margin-right: 30px;
  overflow: hidden;
  white-space: nowrap;
}

.table_cell-text {
  color: #3c3c3d;
  &.success {
    color: green;
  }

  &.danger {
    color: red;
  }
}

.table_header-text-modified {
  margin-right: 10px;
  overflow: hidden;
  white-space: nowrap;
}

.table_cell-text {
  margin-right: 6px;
  margin-left: 6px;
}

.table_empty-label {
  padding: 40px 0;
  text-align: center;
  color: #e1e1e1;
  font-size: 40px;
  font-family: 'robotomedium', 'arial', 'sans-serif';
}

.table_empty-label-center {
  padding: 40px 0; 
}

.table_empty-label td {
  width: 1% !important;
}

.table_rows {
  transition: opacity 0.25s;
  display: block;
}

.table .spinner {
  margin-top: -5px !important;
}

.table_is-loading {
  &:after {
    content: '';
  }
  .table_rows {
    opacity: 0.4;
  }
}

.table {
  &.table-small {
    .table_header {
      height: 34px;
      line-height: 32px;  
      font-size: 11px;
    }
    .table_cell {
      height: 32px;
      line-height: 32px;
      font-size: 11px;
      &.is-single-icon i {
        top: 2px;
      }
    }
  }
  &.table-simple {
    border: none;
    background: none;
    .table_header {
      color: #333;
      background: none;
      font-size: 11px;
      font-family: 'robotobold', 'arial', 'sans-serif';
      &:last-of-type {
        border-right: none;
      }
    }
    .table_cell,
    .table_row {
      padding: 0;
      border: none;
    }
    .table_header {
      padding: 0;
      border: none;
      padding-left: 4px;
      border-bottom: 1px solid #d1d2d4;
    }
    .table_row {
      &:nth-of-type(odd) {
        background: none;
      }
      &:nth-of-type(even) {
        background: #f2f3f4;
      }
    }
    .table_cell {
      padding-left: 4px;
      height: 50px;
      line-height: 50px;
      &.is-single-icon i {
        top: 1px;
      }
    }
  }
}

.table_header {
  .i-sort,
  .i-sort-up,
  .i-sort-down {
    font-size: 17px;
    cursor: pointer;
    user-select: none;
  }
  .i-sort:before,
  .i-sort-up:before,
  .i-sort-down:before {
    position: absolute;
    top: 6px;
    right: 10px;
  }
  .i-sort:hover:before,
  .i-sort-up:hover:before,
  .i-sort-down:hover:before {
    transform: scale(1.25);
  }
  &.is-single-icon {
    padding: 0;
    text-align: center;
    i {
      position: relative;
      top: 3px;
      font-size: 20px;
      cursor: pointer;
      user-select: none;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
}

.table_cell {
  &.is-single-icon {
    padding: 0;
    text-align: center;
    i {
      position: relative;
      top: 3px;
      font-size: 20px;
      cursor: pointer;
      user-select: none;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
  i {
    &.i-edit-square {
      top: 4px;
    }
    &.i-trash,
    &.i-ban {
      color: #dd584f;
    }
  }
  &.is-single-button {
    padding: 0;
    .button_small {
      position: relative;
      bottom: 2px;
    }
  }
  &.is-single-warning-icon {
    padding: 20;
    text-align: left;
    i {
      position: relative;
      top: 3px;
      font-size: 20px;
      cursor: pointer;
      user-select: none;
      color: #dd584f;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
}
