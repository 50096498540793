@font-face {
  font-family: 'robotolight';
  src: url('styles/settings/fonts/assets/roboto-light-webfont.woff2') format('woff2'),
    url('styles/settings/fonts/assets/roboto-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'robotoregular';
  src: url('styles/settings/fonts/assets/roboto-regular-webfont.woff2') format('woff2'),
    url('styles/settings/fonts/assets/roboto-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'robotomedium';
  src: url('styles/settings/fonts/assets/roboto-medium-webfont.woff2') format('woff2'),
    url('styles/settings/fonts/assets/roboto-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'robotobold';
  src: url('styles/settings/fonts/assets/roboto-bold-webfont.woff2') format('woff2'),
    url('styles/settings/fonts/assets/roboto-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@mixin font-light {
  font-family: 'robotolight', 'arial', 'sans-serif';
}
@mixin font-regular {
  font-family: 'robotoregular', 'arial', 'sans-serif';
}
@mixin font-medium {
  font-family: 'robotomedium', 'arial', 'sans-serif';
}
@mixin font-bold {
  font-family: 'robotobold', 'arial', 'sans-serif';
}

.font-light {
  @include font-light;
}
.font-regular {
  @include font-regular;
}
.font-medium {
  @include font-medium;
}
.font-bold {
  @include font-bold;
}
