.toast {
  z-index: 1100;
  position: fixed;
  right: 15px;
  width: 300px;
  padding: 12px;
  transition: all 0.25s;
  background: $color-background-lightest;
  @include box-shadow-small;
  @include font-size(16);
  @include hardware-accelerate;
  &_close {
    position: absolute;
    top: 12px;
    right: 10px;
    font-size: 18px;
    color: $color-text-medium;
    @include clickable;
    &:hover {
      transform: scale(1.2);
    }
  }
  &_title {
    padding-right: 10px;
    @include font-size(16);
    @include font-medium;
    & + .toast_message {
      padding-top: 8px;
    }
  }
  &_message {
    padding-right: 10px;
    @include font-size(15);
  }
  &_cancel, &_confirm {
    margin-top: 15px;
  }
  &_confirm {
    float: right;
  }
  &.toast-enter,
  &.toast-leave.toast-leave-active {
    opacity: 0;
    transform: translateX(10px);
  }
  &.toast-leave,
  &.toast-enter.toast-enter-active {
    opacity: 1;
    transform: translateX(0);
  }
  &.is-success {
    border-top: 5px solid $color-success;
  }
  &.is-danger {
    border-top: 5px solid $color-danger;
  }
  &.is-warning {
    border-top: 5px solid $color-warning;
  }
  &.is-primary {
    border-top: 5px solid $color-primary;
  }
}
