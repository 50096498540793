.spinner {
  z-index: 2;
  height: 50px;
  width: 50px;
  border-style: solid;
  border-width: 6px;
  border-color: rgba(0, 190, 239, 1);
  border-right-color: rgba(0, 190, 239, 0.5);
  border-left-color: rgba(0, 190, 239, 0.5);
  border-radius: 100%;
  animation: spinner 0.75s infinite linear;
  transform-origin: center;
  transition: opacity 150ms ease-in;
  &.spin-enter {
    opacity: 0;
  }
  &.spin-leave {
    &.spin-leave-active {
      opacity: 0;
    }
    opacity: 1;
  }
  &.spin-enter.spin-enter-active {
    opacity: 1;
  }
}

@-webkit-keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
