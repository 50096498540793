.redirect_container {
  height: 100%;
  display: -webkit-flexbox;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;

  &_content {
    margin: auto;

    &_title {
      font-size: 2em;
      font-family: 'robotolight', 'arial', 'sans-serif';
      padding: 40px 0;
    }

    &_icon > img {
      display: block;
      margin: auto;
    }

    &_footertxt {
      text-align: center;
      padding-bottom: 100px;
      @media screen and (max-width: 1368px) {
        padding-bottom: 60px;
      }

      > .error-code {
        padding-top: 40px;
        font-family: 'robotobold', 'arial', 'sans-serif';
        font-size: 1.2em;
      }

      > .redirect-txt {
        padding-top: 5px;
        font-family: 'robotolight', 'arial', 'sans-serif';

        > a {
          color: $color-blue;
          text-decoration: none;
          transition: ease 0.3s;
          &:hover {
            color: darken($color: $color-blue, $amount: 20%);
          }
        }
      }
    }
  }
}
