.box-content {
  flex: 1 auto;
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  flex-wrap: nowrap;
}
.box-content > .box-content-margin {
  flex: 1 auto;
  height: 100%;
  padding: 20px;
  background: #ebebeb;
}
.box-content > .box-content-margin.box-content-no_padding {
  padding: 0;
}
.box-content > .box-content-margin > .box-content-inner {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.box-content:first-child {
  padding-left: 0;
}
.box-content-heading {
  padding: 20px 0 20px;
  font-size: 24px;
  color: #333;
}
.box-content-heading:first-of-type {
  margin-top: 0;
}
.box {
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  align-items: stretch;
}
.box-column {
  flex-direction: column;
}
.box-row {
  flex-direction: row;
}
.box .box-panel {
  flex: 1 auto;
  width: 100%;
}
.box-panel-stretch {
  height: 100%;
}
