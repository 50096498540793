$color-text-splash: #00beef;
$color-placeholder-splash: #6d6e70;
$color-invalid-splash: rgba($color-danger, 0.5);

.splash {
  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
    height: auto;
  }

  min-height: 100%;
  background: #ebebeb;

  &-logo-holder {
    display: inline-block;
    position: relative;
    margin-bottom: 60px;
  }

  &-heading {
    position: absolute;
    bottom: -20px;
    right: 20px;
    color: #00beef;
    font-family: robotolight, arial, 'sans-serif';
    font-size: 30px;
  }

  &-logo {
    width: 100%;
    display: block;
  }

  &-box {
    position: relative;
    padding: 30px 50px 35px;
    width: 480px;
    display: inline-block;
    vertical-align: middle;

    .form_element {
      @include placeholder-color($color-placeholder-splash);
      margin-bottom: 20px;
    }

    .i-exclamation {
      margin: 10px 0 0 22px;
      font-size: 40px;
      color: rgba(221, 88, 79, 0.7) !important;
      position: absolute;
      z-index: 1;
      background: none !important;
    }

    input,
    label,
    a,
    .checkbox_label {
      color: #6d6e70 !important;
    }

    i {
      color: $color-text-splash !important;
      background: #ebebeb !important;
    }

    input,
    .checkbox,
    .select {
      background: none;
      // border: none !important;
    }
    .checkbox {
      .i-check {
        top: 4px;
        left: 4px;
      }
    }
    .input,
    .select {
      margin-bottom: 15px;
    }
    input,
    textarea,
    .checkbox,
    .radio,
    .select {
      &.is-invalid {
        background: $color-invalid-splash;
      }
    }
    .select {
      &:focus {
        border: none;
      }
      &_clear {
        color: $color-placeholder-splash;
      }
      &_triangle {
        border-color: $color-placeholder-splash transparent transparent;
      }
      &_value-cont {
        color: $color-text-splash !important;
      }
      &_placeholder {
        color: $color-placeholder-splash;
      }
      &.is-invalid {
        background: $color-invalid-splash;
      }
    }
    a {
      @include font-size(13);
      &:hover {
        text-decoration: underline;
      }
    }
    .button {
      width: 100%;
    }
  }

  .account__login {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: inset 0 0 0px 9999px #ebebeb;
    }
  }
}
