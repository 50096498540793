// Colors
$color-blue: #00beef;
$color-green: #00a54f;
$color-orange: #f79226;
$color-yellow: #fac51c;
$color-red: #dd584f;
$color-grey: #6d6e70;
$color-lightest: #fff;

$color-text: #333;
$color-text-medium: #777;
$color-text-light: #b3b3b3;
$color-text-lightest: #fff;
$color-background-darkest: #3e3e3f;
$color-background-dark: #999;
$color-background-medium: #e6e7e9;
$color-background-light: #f2f3f4;
$color-background-lightest: #fff;
$color-border: #d1d2d4;
$color-placeholder: $color-grey;
$color-tooltip: #58595b;
$color-selection: rgba($color-blue, 0.4);

$height-small: 30px;
$height-medium: 36px;
$height-large: 42px;

$min-site-width: 1023px;

$color-primary: $color-blue;
$color-success: $color-green;
$color-warning: $color-orange;
$color-danger: $color-red;
$color-secondary: lighten($color-grey, 15%);
$color-disabled: #e6e7e9;

$color-nav-background: #2f2f30;
$color-nav-text: lighten($color-nav-background, 40%);

@each $name, $color in (primary, $color-primary), (success, $color-success),
  (warning, $color-warning), (danger, $color-danger), (secondary, $color-secondary),
  (disabled, $color-disabled)
{
  .color-#{$name} {
    color: #{$color};
    &:before,
    &:after {
      color: #{$color};
    }
  }
  .color-background-#{$name} {
    background: #{$color};
    &:before,
    &:after {
      background: #{$color};
    }
  }
}

$color-avt-modal: #00a54f;

$color-module-organisations: #6daeae;
$color-module-tracking: #2a89c7;
$color-module-fleet: #9ac0df;
$color-module-billing: #e4ba47;
$color-module-loads: #802e53;
$color-module-routes: #282560;
$color-module-fuel: #9ed7cf;
$color-module-gatekeeper: #d12727;
$color-module-courier: #9ac0e0;
$color-module-avt: #99ddd2;
$color-module-insights: #ddd723;
$color-module-planning: #5cbb63;
$color-module-admin: #bb5c5c;
$color-module-developers: #69d2e7;
$color-module-link-facilities: #29abe2;
$color-module-vortex: #32547c;
$color-module-quotations: #00aeef;

@each $name, $color in (organisations, $color-module-organisations),
  (tracking, $color-module-tracking), (fleet, $color-module-fleet), (billing, $color-module-billing),
  (loads, $color-module-loads), (routes, $color-module-routes), (fuel, $color-module-fuel),
  (gatekeeper, $color-module-gatekeeper), (courier, $color-module-courier), (avt, $color-module-avt),
  (insights, $color-module-insights), (planning, $color-module-planning),
  (developers, $color-module-developers), (link-facilities, $color-module-link-facilities),
  (vortex, $color-module-vortex), (quotations, $color-module-quotations)
{
  .color-module-#{$name} {
    color: #{$color};
    &:before,
    &:after {
      color: #{$color};
    }
  }
  .color-background-module-#{$name} {
    background: #{$color};
    &:before,
    &:after {
      background: #{$color};
    }
  }
}
