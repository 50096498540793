.modal_cont {
  z-index: 1001;
  padding: 40px 0;
  outline: none;
  background: rgba(#000, 0.4);
  @extend .stretch;
  @include scrollableVertical(10, rgba($color-background-lightest, 0.5));
  @include hardware-accelerate;
}

.modal {
  position: relative;
  &_header {
    position: relative;
    width: 100%;
    height: 50px;
    line-height: 50px;
    padding-left: 28px;
    color: $color-lightest;
    background: $color-primary;
    @include font-size(22);
    @include font-medium;
    i {
      position: relative;
      top: 3px;
      margin-right: 10px;
      font-size: 26px;
    }
  }
  &_close {
    z-index: 2;
    position: absolute;
    right: 27px;
    top: 10px;
    font-size: 30px;
    color: $color-lightest;
    @include clickable;
    &:hover {
      transform: scale(1.1);
    }
  }
}
