.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.row {
  margin-right: -15px;
  margin-left: -15px;
  @include cf;
}
.col-0-5, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6,
.col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  float: left;
}
.col-12 {
  width: 100%;
}
.col-11 {
  width: 91.66666667%;
}
.col-10 {
  width: 83.33333333%;
}
.col-9 {
  width: 75%;
}
.col-8 {
  width: 66.66666667%;
}
.col-7 {
  width: 58.33333333%;
}
.col-6 {
  width: 50%;
}
.col-5 {
  width: 41.66666667%;
}
.col-4 {
  width: 33.33333333%;
}
.col-3 {
  width: 25%;
}
.col-2 {
  width: 16.66666667%;
}
.col-1-5 {
  width: 12.499999995%;
}
.col-1 {
  width: 8.33333333%;
}
.col-0-5 {
  width: 4.166666665%;
}

// Offsets
.col-offset-1 {
  margin-left: 8.33333333%;
}
.col-offset-2 {
  margin-left: 16.66666667%;
}
.col-offset-3 {
  margin-left: 25%;
}
.col-offset-4 {
  margin-left: 33.33333333%;
}
.col-offset-5 {
  margin-left: 41.66666667%;
}
.col-offset-6 {
  margin-left: 50%;
}
.col-offset-7 {
  margin-left: 58.33333333%;
}
.col-offset-8 {
  margin-left: 66.66666667%;
}
.col-offset-9 {
  margin-left: 75%;
}
.col-offset-10 {
  margin-left: 83.33333333%;
}
.col-offset-11 {
  margin-left: 91.66666667%;
}